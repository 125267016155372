import { render, staticRenderFns } from "./SchemaDataTable.vue?vue&type=template&id=6d616268&scoped=true&"
import script from "./SchemaDataTable.vue?vue&type=script&lang=js&"
export * from "./SchemaDataTable.vue?vue&type=script&lang=js&"
import style0 from "./SchemaDataTable.vue?vue&type=style&index=0&id=6d616268&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d616268",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src1745463177/src/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6d616268')) {
      api.createRecord('6d616268', component.options)
    } else {
      api.reload('6d616268', component.options)
    }
    module.hot.accept("./SchemaDataTable.vue?vue&type=template&id=6d616268&scoped=true&", function () {
      api.rerender('6d616268', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/SchemaManagement/SchemaDataTable.vue"
export default component.exports